import React, { useEffect, useState } from "react";
import "../Styles/SpecificSkill.scss";

function SpecificSkill({ specificSkill, percentage }) {
  const [barWidth, setbarWidth] = useState(0);

  useEffect(() => {
    setbarWidth(percentage);
  }, [percentage]);

  const barWidthStyles = {
    width: `${barWidth}%`,
    transition: "width 1s ease-in-out",
  };
  return (
    <li className="specificSkill">
      <span className="skillName">{specificSkill}</span>
      <span className="percentageBar" style={barWidthStyles}></span>
    </li>
  );
}

export default SpecificSkill;
