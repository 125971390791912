const initialState = {
  skills: [],
};

export default function _(state = initialState, action) {
  switch (action.type) {
    case "SET_SKILLS":
      return {
        skills: action.payload,
      };
    default:
      return state;
  }
}
