import React, { useEffect, useState } from "react";
import "../Styles/Navbar.scss";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import { storage } from "../Firebase";
import { ref, getDownloadURL } from "firebase/storage";
import { NavHashLink } from "react-router-hash-link";

function Navbar() {
  const [activeNav, setActiveNav] = useState(false);
  const [avatarUrl, setAvatarUrl] = useState("");

  useEffect(() => {
    getDownloadURL(ref(storage, "landingPage/images/avatarImage.jpg")).then(
      (url) => {
        setAvatarUrl(url);
      }
    );
  }, []);

  const handleDownload = () => {
    if (
      window.confirm(
        "Do you want to download a copy of my resume in this device?"
      )
    ) {
      const resumeRef = ref(storage, "Resume_pdf.pdf");
      getDownloadURL(resumeRef)
        .then((url) => {
          const link = document.createElement("a");
          link.target = "_blank";
          link.href = url;
          link.download = "Resume_pdf.pdf";

          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };
  return (
    <div id="navbar">
      <span
        onClick={() => {
          setActiveNav(!activeNav);
        }}
        className="openNavIcon"
      >
        <MenuOutlinedIcon style={{ fontSize: 40 }} className="sunIcon" />
      </span>
      <img src={avatarUrl} alt="profileAvatar" className="avatar" />
      <div className={activeNav ? "navElements" : "navElements close"}>
        <NavHashLink smooth to="/#skills" className="navlink">
          Skills
        </NavHashLink>
        <NavHashLink smooth to="/education" className="navlink">
          Education
        </NavHashLink>

        <NavHashLink smooth to="/aboutMe" className="navlink">
          About Me
        </NavHashLink>
        <NavHashLink smooth to="/#contacts" className="navlink">
          Contact Me
        </NavHashLink>
      </div>
      <div onClick={handleDownload} className="resume">
        Resume
      </div>
    </div>
  );
}

export default Navbar;
