import React from "react";
import "../Styles/Contacts.scss";

import GitHubIcon from "@mui/icons-material/GitHub";
import MailIcon from "@mui/icons-material/Mail";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import { useState } from "react";

function Contacts() {
  const [messageBody, setMessageBody] = useState("");
  const [messageSource, setMessageSource] = useState("");

  const [emailLink, setEmailLink] = useState("");

  const handleFormSubmit = (event) => {
    event.preventDefault();
    const subject = "Let's connect";
    const body = `Message from: ${messageSource}\n\n${messageBody}`;
    const mailtoLink = `mailto:rahulmunshi0@gmail.com?subject=${encodeURIComponent(
      subject
    )}&body=${encodeURIComponent(body)}`;
    setEmailLink(mailtoLink);
  };

  const handleBodyChange = (event) => {
    setMessageBody(event.target.value);
  };

  const handleSourceChange = (event) => {
    setMessageSource(event.target.value);
  };
  return (
    <div id="contacts" className="contacts">
      <div className="sendMessage">
        <h2>Get in touch</h2>
        <div className="contactMainContent">
          <form onSubmit={handleFormSubmit}>
            <input
              onChange={handleSourceChange}
              type="text"
              placeholder="Email"
            />
            <textarea
              onChange={handleBodyChange}
              type="text"
              placeholder="Message"
            />
            <button onClick={handleFormSubmit}>Send</button>
          </form>
          <div className="contactsLinks">
            <div
              onClick={() =>
                window.open("https://discord.gg/yV3ujWUy", "_blank")
              }
              className="link"
            >
              <img
                className="discordIcon"
                src="https://images-eds-ssl.xboxlive.com/image?url=Q_rwcVSTCIytJ0KOzcjWTYl.n38D8jlKWXJx7NRJmQKBAEDCgtTAQ0JS02UoaiwRCHTTX1RAopljdoYpOaNfVf5nBNvbwGfyR5n4DAs0DsOwxSO9puiT_GgKqinHT8HsW8VYeiiuU1IG3jY69EhnsQ--&format=source"
                alt=""
              />
            </div>
            <div
              onClick={() =>
                window.open("https://github.com/MoonC432", "_blank")
              }
              className="link"
            >
              <GitHubIcon style={{ fontSize: 50, color: "white" }} />
            </div>

            <div onClick={() => window.open(emailLink)} className="link">
              <MailIcon style={{ fontSize: 50, color: "white" }} />
            </div>

            <div
              onClick={() =>
                window.open(
                  "https://www.linkedin.com/in/rahul-munshi-3a76541b5/",
                  "_blank"
                )
              }
              className="link"
            >
              <LinkedInIcon style={{ fontSize: 50, color: "white" }} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Contacts;
