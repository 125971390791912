import React, { useEffect, useState } from "react";
import "../Styles/LandingSection.scss";
import { storage } from "../Firebase";
import { ref, getDownloadURL } from "firebase/storage";

function LandingSection() {
  const [profileURL, setProfileURL] = useState("");
  const [coverPage, setCoverPage] = useState("");

  useEffect(() => {
    const profileImgRef = ref(
      storage,
      "landingPage/images/profile_linkedIn.png"
    );
    const coverPageRef = ref(storage, "landingPage/images/anime-landing.jpg");
    Promise.all([getDownloadURL(profileImgRef), getDownloadURL(coverPageRef)])
      .then(([profileURL, coverPageURL]) => {
        setProfileURL(profileURL);
        setCoverPage(coverPageURL);
      })
      .catch((error) => {
        console.log("Error fetching images:", error);
      });
  }, []);

  return (
    <div
      style={{
        backgroundImage: `urL(${coverPage})`,
      }}
      className="landingPage"
    >
      <div className="skit1">
        <p>
          My name is <span>Rahul Munshi</span> yo
        </p>
      </div>
      <img className="profileImage" src={profileURL} alt="profileImage" />
      <div className="skit2">
        <p>Now, say my name</p>
      </div>
    </div>
  );
}

export default LandingSection;
