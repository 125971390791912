import React, { useState } from "react";
import "../Styles/SkillBubble.scss";
import SpecificSkill from "./SpecificSkill";

function Skillbubble({ category, relativeSkills }) {
  const [expanded, setExpanded] = useState(false);

  const toggleExpanded = () => {
    setExpanded(!expanded);
  };

  return (
    <div
      className={`categoryBubble${expanded ? " expandedBubble" : ""}`}
      onClick={toggleExpanded}
    >
      <div className="title">{category}</div>
      <ul className="relativeSkills">
        {relativeSkills?.map((skill, index) => {
          const name = Object.keys(skill)[0];
          const percentage = skill[name];
          return (
            <SpecificSkill
              key={index}
              specificSkill={name}
              percentage={percentage}
            />
          );
        })}
      </ul>
    </div>
  );
}

export default Skillbubble;
