const initialState = {
  certifications: [],
};

export default function _(state = initialState, action) {
  switch (action.type) {
    case "SET_CERTIFICATIONS":
      return {
        certifications: action.payload,
      };
    default:
      return state;
  }
}
