// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getStorage } from "firebase/storage";
import { getFirestore } from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCdoiRnyHK20woiSEv5_iikvgFp3Z3h3pk",
  authDomain: "fir-portfolio-72502.firebaseapp.com",
  projectId: "fir-portfolio-72502",
  storageBucket: "fir-portfolio-72502.appspot.com",
  messagingSenderId: "960880853452",
  appId: "1:960880853452:web:2a43b4a4324a3d95585d69",
  measurementId: "G-C5J0KLH132",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export const storage = getStorage(app);
export const db = getFirestore(app);
