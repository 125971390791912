import { collection, getDocs } from "firebase/firestore";
import React, { useEffect } from "react";
import { db } from "../Firebase";

import "../Styles/AboutMe.scss";
import Navbar from "../Components/Navbar";
import Contacts from "../Components/Contacts";
import { useDispatch, useSelector } from "react-redux";

function AboutMe() {
  const aboutMeDetails = useSelector((state) => state.aboutMeReducer.aboutMe);
  const images = [""]; // random images
  const dispatch = useDispatch();
  useEffect(() => {
    const fetchQuery = async () => {
      const aboutMeSnapshot = await getDocs(collection(db, "aboutMe"));
      const aboutMeDocs = aboutMeSnapshot.docs.map((doc) => doc.data());

      dispatch({
        type: "SET_ABOUT_ME",
        payload: aboutMeDocs,
      });
    };
    fetchQuery();
  }, [dispatch]);

  return (
    <div>
      <Navbar />
      <h2 className="aboutMeTitle">About Me</h2>

      <div className="aboutMeBlocks">
        {aboutMeDetails.map((detail, index) => (
          <div key={index} className="block">
            <h3>{detail.title}</h3>
            <p>
              <ul>
                {detail.description.split(".").map((sentence) => (
                  <li className="sentence">{sentence}</li>
                ))}
              </ul>
            </p>
          </div>
        ))}
      </div>
      {/* <div className="aboutMeImages">
        {images.map((img) => (
          <img className="aboutMeRandomImg" src={img} alt="AboutImage"></img>
        ))}
      </div> */}
      <Contacts />
    </div>
  );
}

export default AboutMe;
