import { configureStore } from "@reduxjs/toolkit";

import projectReducer from "./Reducers/ProjectReducer";
import skillsReducer from "./Reducers/SkillsReducer";
import certificationReducer from "./Reducers/CertificationReducer";
import aboutMeReducer from "./Reducers/AboutMeReducer";
const rootReducers = {
  projectReducer: projectReducer,
  skillsReducer: skillsReducer,
  certificationReducer: certificationReducer,
  aboutMeReducer: aboutMeReducer,
};

const store = configureStore({
  reducer: rootReducers,
});

export default store;
