import React, { useEffect, useState } from "react";
import "../Styles/Projects.scss";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { ColorExtractor } from "react-color-extractor";
import Color from "color";
import GitHubIcon from "@mui/icons-material/GitHub";
import {
  connectStorageEmulator,
  getDownloadURL,
  listAll,
  ref,
} from "firebase/storage";
import { db, storage } from "../Firebase";
import { collection, getDocs } from "firebase/firestore";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";

function Projects() {
  const [languagesUsed, setLanguagesUsed] = useState({
    languages: [],
    values: [],
  });

  const [activeProject, setActiveProject] = useState(0);

  const [currentProjectScreenShots, setCurrentProjectScreenShots] = useState(
    []
  );

  const [activeScreenShot, setActiveScreenShot] = useState(0);

  const [bgColor, setBgColor] = useState();

  const dispatch = useDispatch();

  const projects = useSelector((state) => state.projectReducer.projects);

  useEffect(() => {
    const fetchEntities = async () => {
      const querySnapshot = await getDocs(collection(db, "projects"));
      const projectDocs = querySnapshot.docs.map((doc) => doc.data());
      dispatch({
        type: "SET_PROJECTS",
        payload: projectDocs,
      });
    };

    fetchEntities();
  }, [dispatch]);

  useEffect(() => {
    if (projects[activeProject]) {
      fetch(
        `https://api.github.com/repos/${projects[activeProject].repositoryOwner}/${projects[activeProject].repositoryName}/languages`,
        {
          headers: {
            Accept: "application/vnd.github.v3+json",
          },
        }
      )
        .then((response) => response.json())
        .then((data) => {
          const keys = Object.keys(data);
          const values = Object.values(data);
          setLanguagesUsed({ languages: keys, values: values });
        });

      // to get the screenshots of the current project
      const ssref = ref(storage, projects[activeProject].screenShots);

      listAll(ssref).then((result) => {
        const promises = result.items.map((item) => getDownloadURL(item));
        Promise.all(promises).then((urls) => {
          setCurrentProjectScreenShots(urls);
        });
      });
    }
  }, [activeProject, projects]);

  const handleDetailsReload = (index) => {
    setActiveProject(index);
    setActiveScreenShot(0);
  };

  const handleImageSelection = (index) => {
    setActiveScreenShot(index);
  };

  const changeBgColor = (colors) => {
    setBgColor(colors[Math.floor(Math.random() * colors.length) + 1]);
  };

  const openCodeRepo = () => {
    window.open(projects[activeProject].codeLink, "_blank");
  };

  return (
    <div
      style={{
        backgroundColor: bgColor,
        color: Color(bgColor).isLight() ? "#000000" : "#ffffff",
      }}
      className="projectSection"
    >
      <h2>Projects and Repositories</h2>
      <div className="projectSlider">
        <Carousel
          showStatus={false}
          showThumbs={false}
          className="projectCarousel"
          onChange={handleDetailsReload}
        >
          {projects.map((project, index) => (
            <div>
              <ColorExtractor getColors={changeBgColor}>
                <img
                  key={index}
                  src={currentProjectScreenShots[activeScreenShot]}
                  alt="Project Screen Shot"
                  className="projectCard"
                />
              </ColorExtractor>
              <GitHubIcon
                onClick={openCodeRepo}
                style={{
                  backgroundColor: Color(bgColor).isDark()
                    ? "rgba(0,0,0,0.4)"
                    : "rgba(255, 255, 255, 0.4)",
                }}
                className="githubOverlay"
              />
            </div>
          ))}
        </Carousel>
        <div className="projectDetail">
          <h3>{projects[activeProject]?.title}</h3>
          <span className="yearCreated">
            ({projects[activeProject]?.yearCreated})
          </span>
          <div className="languages">
            {languagesUsed.languages.map((language, index) => {
              const totalLines = languagesUsed.values.reduce(
                (total, value) => total + value + 0
              );
              const percentage = (
                (languagesUsed.values[index] / totalLines) *
                100
              ).toFixed(1);

              return (
                <div key={index} className="language">
                  <span>{language}</span>
                  <div className="percentageBar">
                    <div
                      style={{
                        width: percentage + "%",
                        backgroundColor: Color(bgColor).isLight()
                          ? "#3A514B"
                          : "#96BBC2",
                      }}
                      className="percentageBarFill"
                    ></div>
                  </div>
                  <p>{percentage}%</p>
                </div>
              );
            })}
          </div>

          <p>{projects[activeProject]?.description}</p>
          <div className="screenShots">
            {currentProjectScreenShots.map((imageUrl, index) => (
              <img
                key={index}
                className={activeScreenShot === index ? "activeScreenShot" : ""}
                onClick={() => handleImageSelection(index)}
                src={imageUrl}
                alt={"Image Number " + (index + 1)}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Projects;
