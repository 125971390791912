import React, { useEffect, useState } from "react";
import Navbar from "../Components/Navbar";
import "../Styles/Education.scss";
import Contacts from "../Components/Contacts";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../Firebase";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";

function Education() {
  const education = useSelector(
    (state) => state.certificationReducer.certifications
  );

  const images = ""; // link to image folder

  const dispatch = useDispatch();

  useEffect(() => {
    const fetchQuery = async () => {
      const certificationSnapshot = await getDocs(
        collection(db, "certifications")
      );
      const certificationsDocs = certificationSnapshot.docs.map((doc) =>
        doc.data()
      );

      dispatch({
        type: "SET_CERTIFICATIONS",
        payload: certificationsDocs,
      });
    };
    fetchQuery();
  }, [dispatch]);

  return (
    <div>
      <Navbar />
      <h2 className="certificationTitle">Education and Certifications</h2>
      <div className="educationSection">
        {education.map((exp) => (
          <div className="educationCard">
            <h3 className="name">{exp?.certificationName}</h3>
            <div className="educationMetaData">
              <p>
                Start Date: <span>{exp?.startDate}</span>
              </p>
              <p>
                Duration: <span>{exp?.duration}</span> Years
              </p>
              <p>
                GPA (point 4 scale): <span>{exp?.gpa}</span>
              </p>
            </div>
            <hr />
            <div className="educationDescription">
              {exp?.relevantActivities.map((activity) => (
                <p>{activity}</p>
              ))}
            </div>
            <div className="coursesAndAwards">
              <ul>
                {exp?.relavantCourses.map((course) => (
                  <li>{course}</li>
                ))}
              </ul>
              <ul>
                {exp?.honorsAndAwards.map((item) => (
                  <li>{item}</li>
                ))}
              </ul>
            </div>
          </div>
        ))}
      </div>
      {/* Will make a career gallery */}

      {/* <div className="randomImages">
            {imgUrls.map(url=>(
                <img src={url} alt="" className="educationImages" />
            ))}
        </div> */}

      <Contacts />
    </div>
  );
}

export default Education;
