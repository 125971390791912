const initialState = { projects: [] };

export default function _(state = initialState, action) {
  switch (action.type) {
    case "SET_PROJECTS":
      return { projects: action.payload };
    default:
      return state;
  }
}
