import React, { useEffect, useState } from "react";
import "../Styles/Skills.scss";
import Skillbubble from "./Skillbubble";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../Firebase";
import { useDispatch, useSelector } from "react-redux";

function Skills() {
  // const [skillset] = useState([
  //   {
  //     category: "Frontend",
  //     relativeSkills: [
  //       { name: "HTML", percentage: "90" },
  //       { name: "CSS", percentage: "95" },
  //     ],
  //   },
  //   {
  //     category: "Backend",
  //     relativeSkills: [{ name: "Django", percentage: "85" }],
  //   },
  //   {
  //     category: "Database",
  //     relativeSkills: [{ name: "SQL", percentage: "90" }],
  //   },
  //   {
  //     category: "Machine Learning",
  //     relativeSkills: [{ name: "Tensor Flow", percentage: "70" }],
  //   },
  // ]);

  const skillset = useSelector((state) => state.skillsReducer.skills);

  const dispatch = useDispatch();

  useEffect(() => {
    const fetchQuery = async () => {
      const querySnapshot = await getDocs(collection(db, "skills"));
      const skillsDocs = querySnapshot.docs.map((doc) => doc.data());
      dispatch({
        type: "SET_SKILLS",
        payload: skillsDocs,
      });
    };

    fetchQuery();
  }, [dispatch]);
  return (
    <div id="skills">
      <h2>Skills and Abilities</h2>
      <div id="skillsDisplay">
        {skillset?.map((skill, index) => {
          return (
            <Skillbubble
              key={index}
              category={skill.category}
              relativeSkills={skill.relativeSkills}
            />
          );
        })}
      </div>
    </div>
  );
}

export default Skills;
