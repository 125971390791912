const inititalState = {
  aboutMe: [],
};

export default function _(state = inititalState, action) {
  switch (action.type) {
    case "SET_ABOUT_ME":
      return {
        aboutMe: action.payload,
      };
    default:
      return state;
  }
}
