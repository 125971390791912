import React from "react";
import Contacts from "../Components/Contacts";
import LandingSection from "../Components/LandingSection";
import Navbar from "../Components/Navbar";
import Projects from "../Components/Projects";
import Skills from "../Components/Skills";
function Home() {
  return (
    <div>
      <LandingSection />
      <Navbar />
      <Skills />
      <Projects />
      <Contacts />
    </div>
  );
}

export default Home;
